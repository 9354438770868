/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Text, Button, IconLock, Link } from '@bbl-digital/snorre'
import { Wrapper, Content, backgroundStyle, RightWrapper } from './styles'
import { useAuthContext } from 'Auth/AuthContext'
import User from './User'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'shared/store/user/selectors'
import { USER_FETCH_REQUESTED } from 'shared/store/user/actionTypes'
import { useHistory } from 'react-router-dom'
import { useBroadcast } from 'shared/hooks/useBroadcast'
import { CHANNEL_LOGOUT } from 'shared/constants/broadcastChannels'

interface IProps {
  brandingcode: string
}

const Header: React.FC<IProps> = ({ brandingcode }) => {
  const history = useHistory()
  const [top, isTop] = useState(true)
  const { data } = useSelector(getUser)
  const dispatch = useDispatch()
  const { send, subscribe } = useBroadcast<boolean>(CHANNEL_LOGOUT, false, {
    subscribe: true,
  })

  const bblCode = window.config.bblCode

  const authContext = useAuthContext()

  const login = () => {
    authContext.signinRedirect()
  }

  const logout = () => {
    authContext.logout(bblCode)
    send(true)
  }

  useEffect(() => {
    const sub = subscribe(async (isLoggedOut) => {
      if (isLoggedOut) {
        await authContext.UserManager!.removeUser()
        await authContext.UserManager!.clearStaleState()
        await authContext.UserManager!.signoutRedirect()
      }
    })

    return () => sub()
  }, [])

  useEffect(() => {
    if (authContext.isAuthenticated()) {
      authContext.getUser().then((user: any) => {
        if (user.profile.navneregisterid) {
          dispatch({
            type: USER_FETCH_REQUESTED,
            payload: user.profile.navneregisterid,
          })
        } else {
          // DO STUFF WHEN USER DOES NOT HAVE AN NAVNEREGISTERID

          history.push(
            '/ikketilgang?returnUrl=' + window.location.origin + '/logout'
          )

          // window.location.href =
          //   window.config.urls.bblidBaseUrl +
          //   'useradmin/profile/noaccess?returnUrl=' +
          //   window.location.origin +
          //   '/logout'
        }
      })
    }
  }, [authContext, dispatch, history])

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        isTop(false)
      } else {
        isTop(true)
      }
    }

    document.addEventListener('scroll', handleScroll, { passive: true })
  }, [top])

  const goToNoAccess = () => {}

  const goToProfile = () => {
    window.location.replace(
      `${window.config.urls.minSideUrl}/profil?endreProfil=true&returnUrl=${window.location.href}`
    )
  }

  return (
    <Wrapper css={!top ? backgroundStyle : null}>
      <Content>
        <Link nostyle>
          <img
            height="40px"
            style={{ cursor: 'pointer' }}
            alt="logo"
            src={`${
              window.config.urls.themesUrl
            }${brandingcode.toLocaleLowerCase()}/logo.png`}
          />
        </Link>
        {window.location.pathname !== '/ikketilgang' && (
          <RightWrapper>
            {data && (
              <User user={data} onLogout={logout} onGoToProfile={goToProfile} />
            )}
            {!authContext.isAuthenticated() && (
              <Button nostyle beforeIcon={<IconLock />} onClick={login}>
                <Text small semibold>
                  Logg inn
                </Text>
              </Button>
            )}
            {authContext.isAuthenticated() && !data && (
              <Button nostyle beforeIcon={<IconLock />} onClick={goToNoAccess}>
                <Text small semibold>
                  Logg inn
                </Text>
              </Button>
            )}
          </RightWrapper>
        )}
      </Content>
    </Wrapper>
  )
}

export default Header
