import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 4em;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  max-width: 1170px;
  margin: 0 auto;
  min-height: calc(
    100vh - 194px - 10vw - 70px
  ); // Minus footer, topbar and illustration
  padding-top: 10vh;

  @media screen and (max-width: 568px) {
    grid-row-gap: 2em;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  @media screen and (max-width: 1170px) {
    padding: 0 1em;
  }
`

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 365px));
  grid-column-gap: 2em;
  grid-row-gap: 2em;

  @media screen and (max-width: 768px) {
    grid-row-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  h3 {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5em;
    }
  }
`
