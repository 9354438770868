const bblCode = window.config?.bblCode
const bblidUrl = window.config?.urls.bblidBaseUrl + 'host/core'
const appUrl = window.location.origin

const client = window.clientConfigs?.find((x) => {
  return x.BblCode.toLowerCase() === bblCode?.toLowerCase()
})

export const IDENTITY_CONFIG = {
  authority: bblidUrl,
  client_id: client?.ClientId,
  redirect_uri: appUrl + '/signin-oidc',
  silent_redirect_uri: appUrl + '/silentredirect', // COMMENTED OUT UNTIL WE GOT SUPPORT FOR SILENT REDIRECT
  post_logout_redirect_uri: appUrl + '/afterlogout',
  // audience: process.env.REACT_APP_AUDIENCE,
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: false,
  scope: 'openid api profile offline_access',
  response_mode: 'query',
  revokeAccessTokenOnSignout: true,
  client_secret: 'xWUSy5y3TRdte9jp',
  includeIdTokenInSilentRenew: true,
}
