import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILED,
  USER_FETCH_REQUESTED,
} from './actionTypes'
import { getUser, getUserRoles } from 'shared/api/user.api'

function* fetchUser(): any {
  try {
    const [user, userRoles] = yield all([
      call(getUser as any),
      call(getUserRoles as any),
    ])
    yield all([
      put({
        type: USER_FETCH_SUCCESS,
        payload: { ...user.data, roles: { ...userRoles.data } },
      }),
    ])
  } catch (e) {
    yield put({ type: USER_FETCH_FAILED, payload: e })
  }
}

function* userSaga() {
  yield takeEvery(USER_FETCH_REQUESTED, fetchUser)
}

export default userSaga
