import React from 'react'
import styled from '@emotion/styled'
import bp from '@bbl-digital/snorre/lib/globals/styles/breakpoints'
import { styles } from 'shared/constants/styles'

const Wrapper = styled.div<IProps>`
  max-width: ${(props) => (props.fullsize ? 'none' : styles.maxWidth)};
  margin: 0 auto;
  /* padding: ${(props) => (props.fullsize ? '0' : '0 0')}; */

  @media screen and (max-width: ${bp.small}) {
    padding: ${(props) => (props.fullsize ? '0' : '1em 0')};
  }
`

interface IProps {
  fullsize?: boolean
}

const Layout: React.FC<IProps> = (props) => {
  return <Wrapper fullsize={props.fullsize}>{props.children}</Wrapper>
}

export default Layout
