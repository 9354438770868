import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { styles } from 'shared/constants/styles'

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  height: 70px;
  position: sticky;
  z-index: 1000;
  top: 0;
  transition: background-color 0.2s ease-in-out;
`

export const backgroundStyle = css`
  background-color: white;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${styles.maxWidth};
  margin: 0 auto;
  padding: 0 1em;
  width: 100%;

  @media screen and (max-width: 1170px) {
    padding: 0 1em;
  }
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:nth-of-type(1) {
    margin-right: 1em;
  }
`
