import React, { useEffect } from 'react'
import { useAuthContext } from 'Auth/AuthContext'
import { AppLoader } from '@bbl-digital/snorre'
import styled from '@emotion/styled'

const LoaderWrapper = styled.div`
  height: calc(98vh - 300px);
  width: 99vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Callback: React.FC = () => {
  const authContext = useAuthContext()
  useEffect(() => {
    authContext.signinRedirectCallback()
  })
  return (
    <LoaderWrapper>
      <AppLoader />
    </LoaderWrapper>
  )
}

export default Callback
