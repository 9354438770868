import React from 'react'

/**
 *
 * NOT IN USE
 *
 *
 * SEE INDEX.TSX
 *
 */
const SilentRenew: React.FC = () => {
  return <p>Nothing here</p>
}

export default SilentRenew
