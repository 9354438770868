import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getBbl, getBblApplications, getBblSettings } from 'shared/api/bbl.api'
import {
  BBL_FETCH_REQUESTED,
  BBL_FETCH_FAILED,
  BBL_FETCH_SUCCESS,
} from './actionTypes'

function* fetchBbl(): any {
  try {
    const [bbl, bblSettings, bblApplications] = yield all([
      call(getBbl),
      call(getBblSettings),
      call(getBblApplications),
    ])
    yield put({
      type: BBL_FETCH_SUCCESS,
      payload: {
        ...bbl.data,
        settings: { ...bblSettings.data },
        applications: [...bblApplications.data],
      },
    })
  } catch (e) {
    yield put({ type: BBL_FETCH_FAILED, payload: e })
  }
}

function* bblSaga() {
  yield takeEvery(BBL_FETCH_REQUESTED, fetchBbl)
}

export default bblSaga
