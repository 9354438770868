import styled from '@emotion/styled'

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: 0.5s opacity ease-out;
  transition-delay: 0.5s;
  z-index: 9999;

  &.overlay-exit-active {
    opacity: 0;
  }
`
