export const getParamBothLowerUppercase = (paramname: string) => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  let returnUrlParam = params.get(paramname)

  if (returnUrlParam) {
    return returnUrlParam
  } else {
    return params.get(paramname.toLowerCase())
  }
}
