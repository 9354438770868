/** @jsxImportSource @emotion/react */
import React from 'react'
import { UserMenu, UserMenuOption, Button } from '@bbl-digital/snorre'
import { IUser } from 'shared/models/User'

interface IProps {
  user: IUser
  onLogout: () => void
  onGoToProfile: () => void
}

const User: React.FC<IProps> = ({ user, onLogout, onGoToProfile }) => {
  const firstname = user.firstname ? user.firstname + ' ' : ''
  return (
    <UserMenu
      name={firstname + user.surname}
      email={user.email}
      logout={
        <Button trackingName="Usermenu - Logout" outline onClick={onLogout}>
          Logg ut
        </Button>
      }
    >
      <UserMenuOption onClick={onGoToProfile}>Profil</UserMenuOption>
    </UserMenu>
  )
}

export default User
