import { combineReducers, createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { UserReducer } from './user'
import { rootSaga } from './sagas'
import { BblReducer } from './bbl'
import { CustomtextsReducer } from './customtexts'

const rootReducer = combineReducers({
  user: UserReducer,
  bbl: BblReducer,
  customtexts: CustomtextsReducer,
})

const sagaMiddleware = createSagaMiddleware()

const composeEnhancer =
  (process.env.NODE_ENV !== 'production' &&
    (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']) ||
  compose

const store = createStore(
  rootReducer,
  {},
  composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

export default store
