import axios from 'axios'

export const getUser = () => {
  return axios.request({
    method: 'get',
    url: `personv2/Person/`,
    headers: {
      'x-api-version': '2.0',
    },
  })
}

export const getUserRoles = () => {
  return axios.get('/roles/Role')
}
