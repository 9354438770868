import React, { useEffect } from 'react'
import { useAuthContext } from 'Auth/AuthContext'
import { IBBL } from 'shared/models/BBL'
import { AppLoader } from '@bbl-digital/snorre'
import styled from '@emotion/styled'

const LoaderWrapper = styled.div`
  height: calc(98vh - 300px);
  width: 99vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface IProps {
  bbl: IBBL | undefined
}

const Logout: React.FC<IProps> = ({ bbl }) => {
  const authContext = useAuthContext()

  useEffect(() => {
    authContext.signoutRedirectCallback()
  })

  return (
    <LoaderWrapper>
      <AppLoader />
    </LoaderWrapper>
  )
}

export default Logout
