import { PortalTypesEnum } from 'shared/models/BBL'
import { SystemState } from './types'

export const getBbl = (state: SystemState) => state.bbl

export const getBblSettings = (state: SystemState) => {
  const bbl = getBbl(state)
  return bbl.data?.settings
}

export const isPortalActive = (state: SystemState) =>
  state.bbl.data?.applications.findIndex(
    (x) => x.name === PortalTypesEnum.Portal
  ) !== -1

export const isMinSideActive = (state: SystemState) =>
  state.bbl.data?.applications.findIndex(
    (x) => x.name === PortalTypesEnum.MinSide
  ) !== -1

export const isForkjopActive = (state: SystemState) =>
  state.bbl.data?.applications.findIndex(
    (x) => x.name === PortalTypesEnum.Forkjop
  ) !== -1
