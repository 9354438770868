export interface IBBL {
  name: string
  shortName: string
  phone: string
  email: string
  companyNumber: string
  clientNr: number
  externalPartnerNr: string
  businessAddress: string
  brandingCode: string
  settings: IBBLSettings
  gtmCode: string
  applications: IBBLApplications[]
}

export interface IBBLUrls {
  myPage: string
  oldMyPage: string
  financeInfocenter: string
  digitalCourseForResidents: string
  membershipTransfer: string
  newHousingProjects: string
  digitalCourseForBoard: string
  bevarHms: string
  bevarVedlikehold: string
  vedlikeholdSystem: string
  hmsSystem: string
  newPortal: string
  plussplan: string
}

export interface IBBLSettings {
  bblUrl: string
  urls: IBBLUrls
  afterLogoutRedirectUrl: string
  preemptionInformationUrl: string
  commonPreemptionInformationUrl: string
  emailForGeneralQuestions: string
  emailForPortalQuestions: string
  phoneNumberForGeneralQuestions: string
  phoneNumberForPortalQuestions: string
  becomeAMemberUrl: string
  customPrivacyStatementUrl: string
  gtmId: string
}

export interface IBBLApplications {
  name: PortalTypesEnum
}

// Model representing TableStorage entity
export interface IBBLSummary {
  bblCode: string
  name: string
  url: string
  hasPreemption: Boolean
  brandingCode: string
  shortName: string
}

export enum PortalTypesEnum {
  Forkjop = 'Forkjøp',
  Portal = 'Portalen',
  MinSide = 'Min side',
}
