import axios from 'axios'
import { setAxiosLoggedInDefaults } from 'axiosConfig'
import { UserManager, WebStorageStateStore, Log } from 'oidc-client'
import { IDENTITY_CONFIG } from 'shared/constants/authConstants'
import {
  LOCALSTORAGE_IDENTITY_PREFIX,
  LOCALSTORAGE_IDENTITY_MS,
  LOCALSTORAGE_IDENTITY_PORTAL,
  LOCALSTORAGE_IDENTITY_PREEMPTION,
} from 'shared/constants/localStorageVariables'

export default class AuthService {
  UserManager: UserManager

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({
        prefix: LOCALSTORAGE_IDENTITY_PREFIX,
        store: window.localStorage,
      }),
    })
    // Logger
    Log.logger = console
    Log.level = Log.ERROR

    /**
     * Event user loaded
     */
    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen()
      }
    })

    /**
     * Event token expired
     */
    this.UserManager.events.addAccessTokenExpired(() => {
      this.signinSilent()
    })

    /**
     * Event silent renew error
     */
    this.UserManager.events.addSilentRenewError((e) => {
      console.log('addSilentRenewError', e)
    })

    /**
     * Init, if not authenticated try signin silent
     */
    if (
      window.location.pathname.indexOf('silentredirect') === -1 &&
      window.location.pathname.indexOf('signin-oidc') === -1 &&
      !this.isAuthenticated()
    ) {
      this.signinSilent()
    }
  }

  signinRedirect = () => {
    localStorage.setItem(
      'redirectUri',
      window.location.pathname + window.location.search
    )
    this.UserManager.signinRedirect({})
  }

  signinSilent = () => {
    this.UserManager.signinSilent().catch((err) => {})
  }

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback()
  }

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest()
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback()
      .then((e) => {
        const redirectUri = localStorage.getItem('redirectUri')
        window.location.replace(redirectUri ? redirectUri : '/')
      })
      .catch(() => {
        const redirectUri = localStorage.getItem('redirectUri')
        window.location.replace(redirectUri ? redirectUri : '/')
      })
  }

  getUser = async () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.getToken()
    const user = await this.UserManager.getUser()
    if (!user) {
      return await this.UserManager.signinRedirectCallback()
    } else {
      setAxiosLoggedInDefaults()
    }
    return user
  }

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  navigateToScreen = () => {
    window.location.replace('')
  }

  isAuthenticated = () => {
    const fromStorage = localStorage.getItem(
      `${LOCALSTORAGE_IDENTITY_PREFIX}user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
    )
    const oidcStorage = JSON.parse(fromStorage ? fromStorage : '{}')
    const expires = oidcStorage.expires_at * 1000
    const now = new Date().getTime()
    return !!oidcStorage && !!oidcStorage.access_token && expires > now
  }

  getToken = () => {
    const fromStorage = localStorage.getItem(
      `${LOCALSTORAGE_IDENTITY_PREFIX}user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
    )
    const oidcStorage = JSON.parse(fromStorage ? fromStorage : '{}')
    return oidcStorage.access_token
  }

  logout = (bblCode: string) => {
    localStorage.setItem('bblCode', bblCode)

    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    })
    this.UserManager.clearStaleState()
  }

  signoutRedirectCallback = (url?: string) => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.removeItem(
        `${LOCALSTORAGE_IDENTITY_PREFIX}user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
      )
      if (url) {
        window.location.replace(url)
      } else {
        window.location.replace(window.config.baseUrl + window.config.bblCode)
      }
    })
    this.UserManager.clearStaleState()
  }

  cleanup = () => {
    Object.entries(localStorage).forEach(([key]) => {
      if (
        key.includes(LOCALSTORAGE_IDENTITY_PREFIX) ||
        key.includes(LOCALSTORAGE_IDENTITY_MS) ||
        key.includes(LOCALSTORAGE_IDENTITY_PORTAL) ||
        key.includes(LOCALSTORAGE_IDENTITY_PREEMPTION)
      ) {
        localStorage.removeItem(key)
      }
    })

    this.UserManager.clearStaleState()
  }
}
