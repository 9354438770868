import styled from '@emotion/styled'

export const Wrapper = styled.div`
  margin: 10vh auto;
  max-width: 450px;

  h2 {
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 2em;
  }

  p + div {
    margin-bottom: 2em;
  }

  & > div {
    padding: 3em;
  }
`
