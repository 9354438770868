import React from 'react'
import styled from '@emotion/styled'
import { IllustrationError, Header } from '@bbl-digital/snorre'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2em 0;
  & > div {
    height: 210px;
  }
  & > h4 {
    margin: 1em 0;
  }
`

const PageError: React.FC = () => {
  return (
    <Wrapper>
      <IllustrationError />
      <Header level={4}>Det skjedde en feil</Header>
    </Wrapper>
  )
}

export default PageError
