import React from 'react'
import { Skeleton } from '@bbl-digital/snorre'
import { Wrapper } from './styles'

interface IProps {}

const ProductCardSkeleton: React.FC<IProps> = () => {
  return (
    <Wrapper>
      <Skeleton width="90%" />
      <Skeleton width="90%" />
      <Skeleton width="70%" />
    </Wrapper>
  )
}

export default ProductCardSkeleton
