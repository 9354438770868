import {
  BBL_FETCH_REQUESTED,
  BBL_FETCH_SUCCESS,
  BBL_FETCH_FAILED,
} from './actionTypes'
import { BblActionTypes } from './types'

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const reducer = (state = initialState, action: BblActionTypes) => {
  switch (action.type) {
    case BBL_FETCH_REQUESTED:
      return { ...state, loading: true }
    case BBL_FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case BBL_FETCH_FAILED:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default reducer
