import axios from 'axios'

export const getBbl = () => {
  return axios.get(axios.defaults.baseURL + 'bbl/bbl')
}

export const getBblSettings = () => {
  return axios.get(axios.defaults.baseURL + 'bbl/bbl/settings')
}

export const getBblApplications = () => {
  return axios.get(axios.defaults.baseURL + 'bbl/bbl/applications')
}

export const sendRequestToBbl = () => {
  return axios.get(window.config.urls.bblidApiBaseUrl + '/api/user/request')
}
