import React, { Fragment, useEffect, useState } from 'react'
import { Card, Alert, AppLoader } from '@bbl-digital/snorre'
import Layout from 'shared/components/Layout'
import { sendRequestToBbl } from 'shared/api/bbl.api'
import { Wrapper } from './styles'
import { useAuthContext } from 'Auth/AuthContext'
import NoAccessInformation from './NoAccessInformation'
import NoAccessActions from './NoAccessActions'
import { getBbl } from 'shared/store/bbl/selectors'
import { useSelector } from 'react-redux'
import { LoaderWrapper } from 'shared/components/LoaderWrapper/styles'
import axios from 'axios'
import { getParamBothLowerUppercase } from 'shared/utils/urlParams'

interface IProps {}

const NoAccess: React.FC<IProps> = () => {
  const authContext = useAuthContext()
  const bbl = useSelector(getBbl)
  const [idp, setIdp] = useState<string | undefined>()
  const [fullName, setFullName] = useState<string | undefined>()
  const [requestLoading, setRequestLoading] = useState(false)
  const [requestMessage, setRequestMessage] = useState<string | undefined>(
    undefined
  )
  const [error, setError] = useState<string | null>(null)
  const returnUrl = getParamBothLowerUppercase('returnUrl')

  useEffect(() => {
    if (!authContext.isAuthenticated()) {
      authContext.signinRedirect()
      return
    }

    getUser()
    getIdp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getIdp = () => {
    authContext.getUser().then((res) => {
      setIdp(res.profile.idp)
    })
  }

  const getUser = () => {
    authContext.getUser().then((res) => {
      setFullName(res.profile.full_name)
    })
  }

  const onSendRequest = () => {
    setRequestLoading(true)

    sendRequestToBbl()
      .then(() => {
        setRequestMessage('En forespørsel er sendt.')
      })
      .catch(() => {
        setError('En feil skjedde.')
      })
      .finally(() => {
        setRequestLoading(false)
      })
  }

  const onLogout = () => {
    authContext.logout(window.config.bblCode)
  }

  if (bbl && bbl.data?.brandingCode === 'bate') {
    window.location.href =
      window.config.urls.bblidClientUrl +
      'ikketilgang?bbl=bate&returnurl=' +
      window.location.origin +
      '/logout'

    return null
  }

  const Content = (
    <Fragment>
      <NoAccessInformation fullName={fullName} bbl={bbl.data} />
      {requestMessage && (
        <Alert success onClose={() => setRequestMessage(undefined)}>
          {requestMessage}
        </Alert>
      )}
      <NoAccessActions
        bbl={bbl.data}
        returnUrl={returnUrl ? returnUrl : window.location.href}
        idp={idp}
        isRequestSent={!!requestMessage}
        isRequestLoading={requestLoading}
        onSendRequest={onSendRequest}
        onLogout={onLogout}
      />
    </Fragment>
  )

  const ApplicationLoading = (
    <LoaderWrapper>
      <AppLoader />
    </LoaderWrapper>
  )

  return bbl.loading ? (
    ApplicationLoading
  ) : (
    <Layout>
      <Wrapper>
        <Card shadow largePadding>
          {error ? <Alert danger>{error}</Alert> : Content}
        </Card>
      </Wrapper>
    </Layout>
  )
}

export default NoAccess
