import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppShell, AppLoader } from '@bbl-digital/snorre'
import Routes from './Routes'
import GlobalStyles from './GlobalStyles'
import { getTheme, setFavicon } from 'shared/utils/theme'
import { LoaderWrapper } from 'shared/components/LoaderWrapper/styles'
import { getBbl } from 'shared/store/bbl/selectors'
import { BBL_FETCH_REQUESTED } from 'shared/store/bbl/actionTypes'
import { useAuthContext } from 'Auth/AuthContext'
import { CUSTOMTEXTS_FETCH_REQUESTED } from 'shared/store/customtexts/actionTypes'
import { IBBL } from 'shared/models/BBL'

interface IResponse {
  bbl?: IBBL
  error?: any
}

const App: React.FC<IResponse> = () => {
  const { data, loading, error } = useSelector(getBbl)
  const dispatch = useDispatch()
  const [theme, setTheme] = useState<any>()
  const authContext = useAuthContext()

  useEffect(() => {
    dispatch({ type: BBL_FETCH_REQUESTED })
    dispatch({ type: CUSTOMTEXTS_FETCH_REQUESTED })

    if (authContext.isAuthenticated()) {
      authContext.getUser().then((user: any) => {
        dispatch({
          type: BBL_FETCH_REQUESTED,
          payload: user.profile.navneregisterid,
        })
      })
    }
  }, [authContext, dispatch])

  useEffect(() => {
    if (data) {
      getTheme(data.brandingCode).then((res: any) => {
        setTheme(res)
      })
      setFavicon(data.brandingCode)
    }
  }, [data])

  const ApplicationLoading = (
    <LoaderWrapper>
      <AppLoader />
    </LoaderWrapper>
  )
  const ApplicationLoaded = (
    <AppShell theme={theme} defaultTextColor={theme?.secondaryDarkText}>
      <GlobalStyles />
      {data && data?.brandingCode && (
        <Routes bbl={data} brandingcode={data.brandingCode} />
      )}
    </AppShell>
  )

  return (
    <Fragment>
      {!loading && theme ? ApplicationLoaded : ApplicationLoading}
      {error ? <span>Noe gikk galt...</span> : null}
    </Fragment>
  )
}

export default App
