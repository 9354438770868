import React, { useEffect } from 'react'
import { useAuthContext } from 'Auth/AuthContext'
import { LoaderWrapper } from 'shared/components/LoaderWrapper/styles'
import { AppLoader } from '@bbl-digital/snorre'

const Cleanup: React.FC = () => {
  const authContext = useAuthContext()
  useEffect(() => {
    authContext.cleanup()
  })
  return (
    <LoaderWrapper>
      <AppLoader></AppLoader>
    </LoaderWrapper>)
}

export default Cleanup
