import React from 'react'
import { Text, Button, Link } from '@bbl-digital/snorre'
import { ButtonWrapper } from './styles'
import { IBBL } from 'shared/models/BBL'

interface IProps {
  returnUrl: string | undefined
  bbl: IBBL | null
  idp: string | undefined
  isRequestLoading: boolean
  isRequestSent: boolean
  onSendRequest: () => void
  onLogout: () => void
}

const NoAccessActions: React.FC<IProps> = ({
  returnUrl,
  bbl,
  idp,
  isRequestLoading,
  isRequestSent,
  onSendRequest,
  onLogout,
}) => {
  return (
    <ButtonWrapper>
      <Button
        loading={isRequestLoading}
        disabled={isRequestSent}
        highlight
        onClick={onSendRequest}
      >
        Send forespørsel
      </Button>
      {returnUrl && (
        <Button highlight outline onClick={onLogout}>
          Logg ut
        </Button>
      )}
      {idp === 'oidc2' && (
        <Link
          href={
            window.config.urls.bblidClientUrl +
            `profil?bbl=${window.config.bblCode}&returnUrl=${returnUrl}`
          }
        >
          Min brukerprofil
        </Link>
      )}

      <Text subtle>
        Du kan ringe oss på {bbl?.phone} for en raskere behandling, men vi
        oppfordrer deg uansett til å trykke på knappen over, slik at vi kan se
        hva du har oppgitt som dine brukerdetaljer.
      </Text>
    </ButtonWrapper>
  )
}

export default NoAccessActions
