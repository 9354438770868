import axios from 'axios'
export const getTheme = async (brandingCode: string) => {
  const code = brandingCode.toLowerCase()

  const style: any = await axios.get(
    window.config.urls.themesUrl + code + '/styles.json',
    {
      transformRequest: (data, headers) => {
        delete headers.common['Authorization']
      },
    }
  )

  return style.data
}

export const setFavicon = (code: string) => {
  const favicon: any = document.querySelector("link[rel*='icon']")
  if (!favicon) return
  favicon.href = `${
    window.config.urls.themesUrl
  }${code.toLocaleLowerCase()}/favicon.ico`
}
