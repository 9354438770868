import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const TextWrapper = styled.div`
  min-height: 60px;
  margin-top: 0.5em;
  margin-bottom: 1em;

  @media screen and (max-width: 768px) {
    display: none;
    margin-top: 0em;
    margin-bottom: 0em;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ContentWrapper = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const BorderRadiusStyle = css`
  @media screen and (max-width: 768px) {
    padding: 1.5em 1em;
    border-radius: 15px;
  }
`
