import React, { useEffect } from 'react'
import { useAuthContext } from 'Auth/AuthContext'
import { AppLoader, getUrlWithProtocol } from '@bbl-digital/snorre'
import styled from '@emotion/styled'
import { IBBL } from 'shared/models/BBL'

const LoaderWrapper = styled.div`
  height: 98vh;
  width: 99vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface IProps {
  bbl: IBBL | undefined
}

const AfterLogout: React.FC<IProps> = ({ bbl }) => {
  const authContext = useAuthContext()

  useEffect(() => {
    const url = bbl?.settings?.bblUrl
      ? getUrlWithProtocol(bbl.settings.bblUrl)
      : undefined

    authContext.signoutRedirectCallback(url)
  })

  return (
    <LoaderWrapper>
      <AppLoader></AppLoader>
    </LoaderWrapper>
  )
}

export default AfterLogout
