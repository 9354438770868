import {
  CUSTOMTEXTS_FETCH_FAILED,
  CUSTOMTEXTS_FETCH_REQUESTED,
  CUSTOMTEXTS_FETCH_SUCCESS,
} from './actionTypes'
import { CustomtextsActionTypes } from './types'

const initialState = {
  data: [],
  loading: false,
  error: null,
}

const reducer = (state = initialState, action: CustomtextsActionTypes) => {
  switch (action.type) {
    case CUSTOMTEXTS_FETCH_REQUESTED:
      return { ...state, loading: true }
    case CUSTOMTEXTS_FETCH_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case CUSTOMTEXTS_FETCH_FAILED:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default reducer
