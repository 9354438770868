import { Header, Skeleton, Text } from '@bbl-digital/snorre'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import {
  getCustomtextHeader,
  getCustomtextIngress,
} from 'shared/store/customtexts/selectors'
import { HeaderWrapper, IngressWrapper } from './styles'

const HomeHeader: React.FC = () => {
  const customtextHeader = useSelector(getCustomtextHeader)
  const customtextIngress = useSelector(getCustomtextIngress)
  return (
    <HeaderWrapper>
      <Header level={2}>{customtextHeader || 'Velkommen'}</Header>
      <IngressWrapper>
        {customtextIngress ? (
          <Text>{customtextIngress}</Text>
        ) : (
          <Fragment>
            <Skeleton width="100%" />
            <Skeleton width="60%" />
          </Fragment>
        )}
      </IngressWrapper>
    </HeaderWrapper>
  )
}

export default HomeHeader
