import React from 'react'
import { Global, css } from '@emotion/react'

const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Source Sans Pro';
        src: url('assets/fonts/SourceSansPro-Regular.ttf');
        font-weight: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Source Sans Pro';
        src: url('assets/fonts/SourceSansPro-BlackItalic.ttf');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Source Sans Pro';
        src: url('assets/fonts/SourceSansPro-SemiBold.ttf');
        font-weight: 600;
        font-display: swap;
      }
      @font-face {
        font-family: 'Source Sans Pro';
        src: url('assets/fonts/SourceSansPro-SemiBoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Source Sans Pro';
        src: url('assets/fonts/SourceSansPro-Bold.ttf');
        font-weight: 700;
        font-display: swap;
      }
      @font-face {
        font-family: 'Source Sans Pro';
        src: url('assets/fonts/SourceSansPro-BoldItalic.ttf');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
      }

      * {
        font-family: 'Source Sans Pro', sans-serif;
      }
      body {
        margin: 0;
        font-family: 'Source Sans Pro', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: overlay;
        overflow-x: hidden;
      }
      /* Customize website's scrollbar like Mac OS
      Not supports in Firefox and IE */

      /* total width */
      body::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
      }

      /* background of the scrollbar except button or resizer */
      body::-webkit-scrollbar-track {
        background-color: transparent;
      }

      /* scrollbar itself */
      body::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid transparent;
      }

      /* set button(top and bottom of the scrollbar) */
      body::-webkit-scrollbar-button {
        display: none;
      }
    `}
  />
)

export default GlobalStyles
