import axios from 'axios'
import { IDENTITY_CONFIG } from 'shared/constants/authConstants'
import { LOCALSTORAGE_IDENTITY_PREFIX } from 'shared/constants/localStorageVariables'

export const setAxiosDefaults = () => {
  axios.defaults.baseURL = window.config.api.apiProxyUrl

  axios.defaults.headers.common['bblCode'] = window.config.bblCode
  axios.defaults.headers.common['APIM-BBLCode'] = window.config.bblCode
}

export const setAxiosLoggedInDefaults = () => {
  const fromStorage = localStorage.getItem(
    `${LOCALSTORAGE_IDENTITY_PREFIX}user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
  )
  const oidcStorage = JSON.parse(fromStorage ? fromStorage : '{}')

  axios.defaults.baseURL = window.config.api.url
  axios.defaults.headers.post['x-api-version'] = axios.defaults.headers.common[
    'Ocp-Apim-Subscription-Key'
  ] = window.config.api.subscription
  axios.defaults.headers.common['APIM-BBLCode'] = window.config.bblCode
  axios.defaults.headers.common['Authorization'] =
    'Bearer ' + oidcStorage.access_token
}
