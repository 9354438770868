import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from 'App'
import { AuthProvider } from 'Auth/AuthContext'
import { UserManager } from 'oidc-client'
import { Provider } from 'react-redux'
import store from 'shared/store/store'
import { setAxiosDefaults } from 'axiosConfig'
import { LOCALSTORAGE_IDENTITY_PREFIX } from 'shared/constants/localStorageVariables'
import { IDENTITY_CONFIG } from 'shared/constants/authConstants'

setAxiosDefaults()

if (window.location.pathname.indexOf('/silentredirect') !== -1) {
  new UserManager({}).signinSilentCallback().then()
} else if (window.location.pathname.indexOf('/cleanup') !== -1) {
  // Iframe cleanup call
  localStorage.removeItem(
    `${LOCALSTORAGE_IDENTITY_PREFIX}user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`
  )
  new UserManager({}).clearStaleState()
} else {
  ReactDOM.render(
    <AuthProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

declare global {
  interface Window {
    clientConfigs: [
      {
        BblCode: string
        ClientId: string
      }
    ]
    config: {
      bblCode: string
      appName: string
      baseUrl: string
      urls: {
        bblidBaseUrl: string
        bblidClientUrl: string
        bblidApiBaseUrl: string
        minSideUrl: string
        forkjopUrl: string
        portalUrl: string
        themesUrl: string
      }
      api: {
        apiProxyUrl: string
        url: string
        subscription: string
        version: string
      }
      gtm: {
        bblPivotalGtmCode: string
      }
      gmaps: {
        googleMapKey: string
      }
      ai: {
        instrumentationKey: string
        role: string
      }
    }
  }
}
