import { Card, Header, IconArrowRight, Link, Text } from '@bbl-digital/snorre'
import { useTheme } from '@emotion/react'
import React from 'react'
import ProductCardSkeleton from './ProductCardSkeleton'
import {
  BorderRadiusStyle,
  ContentWrapper,
  IconWrapper,
  TextWrapper,
} from './styles'

interface IProps {
  headerIcon: React.ReactNode
  headerText: string
  text?: string
  url: string
}

const ProductCard: React.FC<IProps> = ({
  headerIcon,
  headerText,
  text,
  url,
}) => {
  const theme: any = useTheme()

  return (
    <Link nostyle href={url}>
      <Card largePadding shadow withhover css={BorderRadiusStyle}>
        <ContentWrapper>
          <Header level={3}>
            {headerIcon}
            {headerText}
          </Header>
          <TextWrapper>
            {text ? <Text paragraph>{text}</Text> : <ProductCardSkeleton />}
          </TextWrapper>
          <IconWrapper>
            <IconArrowRight color={theme.primary} />
          </IconWrapper>
        </ContentWrapper>
      </Card>
    </Link>
  )
}

export default ProductCard
