import styled from '@emotion/styled'

export const HeaderWrapper = styled.div`
  h2 {
    margin-bottom: 0.5em;
  }
  span {
    font-size: 20px;
  }
`

export const IngressWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  max-width: 500px;
  flex-wrap: wrap;
`
