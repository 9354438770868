import React, { Fragment } from 'react'
import { Header, Text } from '@bbl-digital/snorre'
import { IBBL } from 'shared/models/BBL'

interface IProps {
  bbl: IBBL | null
  fullName: string | undefined
}

const NoAccessInformation: React.FC<IProps> = ({ fullName, bbl }) => {
  return (
    <Fragment>
      <Header level={2}>Hei {fullName}</Header>
      <Text paragraph>
        Vi klarte dessverre ikke å automatisk koble din bruker i {bbl?.name}{' '}
        sine systemer.
      </Text>
      <Text paragraph>
        Om du finnes i {bbl?.name} sine systemer kan vårt kundesenter gjerne
        hjelpe deg videre med opprettingen.
      </Text>
      <Text paragraph>
        Trykk på knappen under for å sende en forespørsel med dine
        brukerdetaljer til vårt kundesenter hos {bbl?.name}.
      </Text>
    </Fragment>
  )
}

export default NoAccessInformation
