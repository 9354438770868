import React, { useState, useEffect, Suspense, Fragment } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import history from 'browserHistory'
import styled from '@emotion/styled'
import { AnalyticsProvider } from '@bbld/bbl-analytics'

// import TelemetryProvider from 'shared/tracking/telemetryProvider'
import { getAppInsights } from 'shared/services/telemetryService'
import PageError from 'shared/components/PageError'
import Callback from 'Auth/Callback'
import Logout from 'Auth/Logout'
import SilentRenew from 'Auth/SilentRenew'
import Header from './Header'
import LandingFooter from './Footer'
import { IBBL } from 'shared/models/BBL'
import Home from 'Modules/Home'
import AfterLogout from 'Auth/AfterLogout'
import Cleanup from 'Auth/Cleanup'
import NoAccess from 'Modules/NoAccess'

/** Lazy load modules */
const TelemetryProvider = React.lazy(
  () => import('shared/tracking/telemetryProvider')
)
const PrivacyPage = React.lazy(() => import('Modules/PrivacyPage'))

const Wrapper = styled.main`
  min-height: calc(100vh - 210px);
`

interface IProps {
  brandingcode: string
  bbl: IBBL
}

const Routes: React.FC<IProps> = ({ bbl, brandingcode, ...props }) => {
  const [isReady, setIsReady] = useState(false)
  const [analyticsSettings, setAnalyticsSettings] = useState<any>({
    gtmCodes: [window.config.gtm.bblPivotalGtmCode],
    siteName: window.config.appName,
    bblCode: window.config.bblCode,
  })

  useEffect(() => {
    console.log('analytics settings')
    if (bbl.settings.gtmId) {
      setAnalyticsSettings({
        ...analyticsSettings,
        gtmCodes: [...analyticsSettings.gtmCodes, 'GTM-' + bbl.settings.gtmId],
      })
    }
    setTimeout(() => {
      setIsReady(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line
  let appInsights: any | null = null

  return (
    <Wrapper>
      <Suspense fallback={<Fragment />}>
        {isReady && (
          <AnalyticsProvider settings={analyticsSettings} history={history}>
            <Router history={history}>
              <TelemetryProvider
                instrumentationKey={window.config.ai.instrumentationKey}
                after={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  appInsights = getAppInsights()
                }}
              >
                <Header brandingcode={brandingcode} />
                <Switch>
                  {/* Auth routes */}
                  <Route path="/silentrenew" component={SilentRenew} />
                  <Route path="/signin-oidc" component={Callback} />
                  <Route path="/cleanup" component={Cleanup} />
                  <Route
                    path="/logout"
                    render={(props) => <Logout bbl={bbl} />}
                  />
                  <Route
                    path="/afterlogout"
                    render={(props) => <AfterLogout bbl={bbl} />}
                  />

                  <Route path="/" exact component={Home} />
                  <Route path="/ikketilgang" exact component={NoAccess} />
                  <Route path="/personvern" exact component={PrivacyPage} />
                  {/* Error routes */}
                  <Route component={PageError} />
                </Switch>
                <LandingFooter bbl={bbl} />
              </TelemetryProvider>
            </Router>
          </AnalyticsProvider>
        )}
      </Suspense>
    </Wrapper>
  )
}

export default Routes
