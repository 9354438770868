import styled from '@emotion/styled'
import Link from '@bbl-digital/snorre/lib/core/Link'

export const FooterWrapper = styled.footer`
  position: relative;
  margin-top: -20px;

  @media screen and (max-width: 770px) {
    margin-top: -10px;
  }

  & > div {
    &:nth-of-type(2) {
      padding: 2em 1em;
      width: calc(100% - 2em);
    }

    & > svg {
      margin-left: -10px;
      height: 100%;
      margin-bottom: -7px;
      z-index: -1;
    }
  }
`

export const CustomLink = styled(Link)`
  background-color: transparent;
  border: none;
  padding: 0;
  color: white;
  font-size: 16px;
  font-weight: normal;
  text-decoration: underline;
  margin-bottom: 6px;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: left;
`
