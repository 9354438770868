import React, { Fragment } from 'react'
import {
  FooterLink,
  FooterLabel,
  FooterGroup,
  FooterCol,
  Text,
  Footer,
  IllustrationHousesBackgroundWithComposition,
  getFormatedPhone,
  getUrlWithProtocol,
} from '@bbl-digital/snorre'
import { FooterWrapper, CustomLink } from './styles'
import { IBBL } from 'shared/models/BBL'
import { useHistory } from 'react-router-dom'

interface IProps {
  bbl?: IBBL
}

const LandingFooter: React.FC<IProps> = ({ bbl }) => {
  const history = useHistory()

  const phone = bbl?.settings?.phoneNumberForGeneralQuestions
  const email = bbl?.settings?.emailForGeneralQuestions

  const hasAnyUrl = (): boolean => {
    if (!bbl?.settings) return false
    return bbl.settings.commonPreemptionInformationUrl ||
      bbl.settings.preemptionInformationUrl ||
      bbl.settings.becomeAMemberUrl
      ? true
      : false
  }

  const goToPrivacy = () => {
    history.push('/personvern')
  }

  const currentYear = new Date().getFullYear()

  return (
    <FooterWrapper>
      <IllustrationHousesBackgroundWithComposition height="159px" />
      <Footer>
        {bbl && bbl.settings && (
          <Fragment>
            <FooterCol>
              {phone && (
                <FooterGroup>
                  <FooterLabel>Telefon</FooterLabel>
                  <FooterLink href={'tel:' + phone}>
                    {getFormatedPhone(phone)}
                  </FooterLink>
                </FooterGroup>
              )}
              {email && (
                <FooterGroup>
                  <FooterLabel>E-post</FooterLabel>
                  <FooterLink href={`mailto:${email}`}>{email}</FooterLink>
                </FooterGroup>
              )}
            </FooterCol>

            <FooterCol>
              {hasAnyUrl() && (
                <FooterGroup>
                  <FooterLabel>Nyttige lenker</FooterLabel>
                  {bbl.settings.bblUrl && (
                    <FooterLink
                      ifexists
                      href={getUrlWithProtocol(bbl.settings.bblUrl)}
                    >
                      {bbl.shortName || bbl.name}
                    </FooterLink>
                  )}
                  {bbl.settings.becomeAMemberUrl && (
                    <FooterLink
                      ifexists
                      href={getUrlWithProtocol(bbl.settings.becomeAMemberUrl)}
                    >
                      Bli medlem
                    </FooterLink>
                  )}
                </FooterGroup>
              )}
            </FooterCol>

            <FooterCol>
              <FooterGroup>
                <Text color={'white'}>BBL Pivotal © {currentYear}</Text>
                {bbl.settings.customPrivacyStatementUrl && (
                  <FooterLink
                    small
                    ifexists
                    href={getUrlWithProtocol(
                      bbl.settings.customPrivacyStatementUrl
                    )}
                  >
                    Personvern og informasjonskapsler
                  </FooterLink>
                )}
                {!bbl.settings.customPrivacyStatementUrl && (
                  <CustomLink nostyle onClick={goToPrivacy}>
                    Personvern og informasjonskapsler
                  </CustomLink>
                )}
              </FooterGroup>
            </FooterCol>
          </Fragment>
        )}
      </Footer>
    </FooterWrapper>
  )
}

export default LandingFooter
