import {
  CUSTOMTEXT_FORKJOP,
  CUSTOMTEXT_HEADER,
  CUSTOMTEXT_INGRESS,
  CUSTOMTEXT_MINSIDE,
  CUSTOMTEXT_PORTAL,
} from 'shared/constants/customtexts'
import { SystemState } from './types'

export const getCustomtexts = (state: SystemState) => state.customtexts

export const getCustomtextHeader = (state: SystemState) =>
  state.customtexts.data?.find((x) => x.fieldName === CUSTOMTEXT_HEADER)?.text

export const getCustomtextIngress = (state: SystemState) =>
  state.customtexts.data?.find((x) => x.fieldName === CUSTOMTEXT_INGRESS)?.text

export const getCustomtextPortal = (state: SystemState) =>
  state.customtexts.data?.find((x) => x.fieldName === CUSTOMTEXT_PORTAL)?.text

export const getCustomtextMinside = (state: SystemState) =>
  state.customtexts.data?.find((x) => x.fieldName === CUSTOMTEXT_MINSIDE)?.text

export const getCustomtextForkjop = (state: SystemState) =>
  state.customtexts.data?.find((x) => x.fieldName === CUSTOMTEXT_FORKJOP)?.text
