import React, { useContext } from 'react'
import AuthService from 'shared/services/authService'

type Service = AuthService

const AuthContext = React.createContext<Service | undefined>(undefined)

type AuthProviderProps = { children: React.ReactNode }

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  return (
    <AuthContext.Provider value={new AuthService()}>
      {props.children}
    </AuthContext.Provider>
  )
}

function useAuthContext() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuthContext }
