import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getCustomtext } from 'shared/api/customtext.api'
import {
  CUSTOMTEXT_HEADER,
  CUSTOMTEXT_FORKJOP,
  CUSTOMTEXT_INGRESS,
  CUSTOMTEXT_MINSIDE,
  CUSTOMTEXT_PORTAL,
} from 'shared/constants/customtexts'
import { ICustomtext } from 'shared/models/Customtext'
import {
  CUSTOMTEXTS_FETCH_FAILED,
  CUSTOMTEXTS_FETCH_REQUESTED,
  CUSTOMTEXTS_FETCH_SUCCESS,
} from './actionTypes'

function* fetchCustomtexts(): any {
  try {
    const ids = [
      CUSTOMTEXT_HEADER,
      CUSTOMTEXT_FORKJOP,
      CUSTOMTEXT_INGRESS,
      CUSTOMTEXT_MINSIDE,
      CUSTOMTEXT_PORTAL,
    ]
    const response = yield all(ids.map((x) => call(getCustomtext, x)))
    const customtexts: ICustomtext[] = response.map((c: any) => c.data)

    yield put({
      type: CUSTOMTEXTS_FETCH_SUCCESS,
      payload: customtexts,
    })
  } catch (e) {
    yield put({ type: CUSTOMTEXTS_FETCH_FAILED, payload: e })
  }
}

function* customtextsSaga() {
  yield takeEvery(CUSTOMTEXTS_FETCH_REQUESTED, fetchCustomtexts)
}

export default customtextsSaga
