import styled from '@emotion/styled'

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 1em;
  }

  a {
    margin-top: 1em;
    margin-bottom: 2em;
    text-align: center;
  }
`
